<template>
    <div class="panel panel-default toggle_container">
        <div class="panel-heading" id="group_by_row">
            <label>
                <input type="radio" :checked="state.isGroupBy" name="params[is_group_by]" @click="groupByToggle"> Group By
            </label>
        </div>
        <div class="panel-body report_options" v-show="state.isGroupBy">
            <div>
                <div v-for="(field, key) in state.groupByFields" v-bind:key="key">
                    <input class="group_by" type="checkbox" @click="setGroupByFields" :value="key" :id="`group_by_${key}`" :checked="field.checked"> <label :for="`group_by_${key}`">{{field.label}}</label>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
    import { store } from '@/store/Report.store.js'
    export default {
        name: "GroupBy",
        data() {
            return {
                state: store.state,
            }
        },
        methods: {
            groupByToggle: function() {
                store.state.isGroupBy = true;
            },
            setGroupByFields: function(e) {
                store.setGroupByFields(e);
            }
        }
    }
</script>

<style scoped>

</style>