<template>
    <div>
        <h1>Production Sales Value</h1>
        <form @submit.prevent="runReport">
            <Options />
            <GroupBy />
            <Fields />
            <Results />
        </form>
    </div>
</template>
<script>
    import { store } from "@/store/Report.store.js";
    import Options from "./production_sales_value_opt"
    import GroupBy from "./utils/GroupBy";
    import Fields from "./utils/Fields";
    import Results from "./utils/Results";
    export default {
        name: 'production_sales_value',
        components: {Results, Fields, GroupBy, Options},
        data() {
            return {
                state: store.state,
                optionFields: {
                    startDate: {val: `${new Date(new Date().setDate(new Date().getDate() - new Date().getDay() - 7)).toLocaleDateString()} 00:00`, qsField: '[dateandtimes][tt.start_time_from]'},
                    endDate: {val: `${new Date(new Date().setDate(new Date().getDate() - new Date().getDay() - 1)).toLocaleDateString()} 00:00`, qsField: '[dateandtimes][tt.start_time_to]'},
                    cdiLocationId: {val: 0, qsField: '[numbers][tr.cdi_location_id]'}
                },
                groupByFields: {
                    cdi_location:   {label: 'Location', checked: true},
                    task:           {label: 'Task', checked: false}
                },
                dataFields: {
                    tracker_id:         {label: 'Order', checked: true},
                    tracker_invoice_id: {label: 'Item', checked: false},
                    description:        {label: 'Description', checked: true},
                    task:               {label: 'Task', checked: true},
                    t_start:            {label: 'Start', checked: false},
                    t_end:              {label: 'Stop', checked: false},
                    duration:           {label: 'Mins', checked: false},
                    labor_per:          {label: 'Labor %', checked: true},
                    final_price:        {label: 'Price', checked: true},
                    task_points:        {label: 'Points', checked: false},
                    rework:             {label: 'Rework', checked: true},
                }
            }
        },
        methods: {
            runReport() {
                store.runReport();
            }
        },
        created() {
            this.$appStore.setTitle(['Production Sales Value']);
            this.$set(store.state.results, this.$options.name, '');
            store.state.runOnLoad = false;
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }
</script>