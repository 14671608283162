<template>
    <div>
        <div
            @keypress.enter="emitEnterKeyPress($event.target)"
            @click="clickEvent($event.target)"
            class="table table-responsive results"
            id=""
            v-html="state.results[state.report].html"
        ></div>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js'
    export default {
        name: "Results",
        data() {
            return {
                state: store.state,
            }
        },
        methods: {
            clickEvent: function(el) {
                if (el.hasAttribute('cdi_value') && el.className.split(' ').indexOf('sortable') != -1) {
                    store.sortReport(el.getAttribute('cdi_value').trim());
                } else {
                    this.$emit('clicked', el);
                }
            },
            emitEnterKeyPress(el) {
                this.$emit('enterKeyPressed', el);
            }
        }
    }
</script>

<style scoped>
.table.table-responsive{
    overflow-y:auto!important;
}
#reportResults {
    border: #cecece;
    border-style: solid;
    border-width: 1px;
    max-height: 80vh;
}
</style>



