<template>
    <div class="panel panel-default toggle_container">
        <div class="panel-heading">
            <label>
                <input type="radio" :checked="!state.isGroupBy" name="params[is_group_by]" @click="groupByToggle"> Data Fields
            </label>
        </div>
        <div class="panel-body report_options" v-show="!state.isGroupBy">
            <div>
                <div v-for="(field, key) in state.dataFields" v-bind:key="key">
                    <input class="fields" type="checkbox" @click="setDataFields" :value="key" :id="`fields_${key}`" :checked="field.checked" :disabled="field.disabled"> <label :for="`fields_${key}`">{{field.label}}</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js'
    export default {
        name: "Fields",
        data() {
            return {
                state: store.state,
            }
        },
        methods: {
            groupByToggle: function() {
                store.state.isGroupBy = false;
            },
            setDataFields: function(e) {
                store.setDataFields(e);
            }
        }
    }
</script>

<style scoped>

</style>